class VideoTemplateModal extends BATComponent {
	/* Lifecycle event callbacks */
	beforeLoad() {
		// console.log('VideoTemplateDefault before load');
	}

	afterLoad() {
		this.$el = $(this);
		const $videoIframe = this.$el.find('.bat-video-iframe');
		let videoURL = $videoIframe.attr('src').split('?')[0];
		let videoQueryString = $videoIframe.attr('src').split('?')[1];
		const isChromium = window.chrome;
		const mutedVideo = isChromium ? '&muted=true&mute=true' : '';

		// Bind Modal Events.
		Modal.bindModalEvents(this.$el);

		const bindEvents = () => {
			setInterval(() => {
				$.each($('[data-cta-type="video"]'), (index, $button) => {
					const button = $($button);
					button.off('click');
					button.on('click', (ev) => {
						ev.preventDefault();
						ev.stopPropagation();
						let urlParam = button.data('parameters');
						if (!urlParam) {
							urlParam = button.attr('href');
							if (urlParam.indexOf('https://') > -1) {
								urlParam = 'https://' + urlParam.split('https://')[1];
							}
						}
						videoURL = urlParam.split('?')[0];
						videoQueryString = urlParam.split('?')[1];
						$(`#video-modal`).addClass('active').trigger('modal:open');
					});
				});
			}, 1000);

			// Modal Event listeners
			this.$el.on('modal:open', () => {
				$videoIframe.attr(
					'src',
					`${videoURL}?autoplay=1${
						videoQueryString ? `&${videoQueryString}` : ''
					}${mutedVideo}`
				);
			});

			this.$el.on('modal:close', () => {
				$videoIframe.attr('src', '');
			});
		};

		// Bind Button Events
		bindEvents();
	}

	beforeUpdate() {
		// console.log('VideoTemplateDefault before update');
	}

	afterUpdate() {
		// console.log('VideoTemplateDefault after update');
	}

	unload() {
		// console.log('VideoTemplateDefault after unload');
	}
}

!customElements.get('bat-video-modal') &&
	customElements.define('bat-video-modal', VideoTemplateModal);
